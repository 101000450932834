/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Login = ({ config }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [userId, setUserId] = useState('');
  const [regenerateCode, setRegenerateCode] = useState(false);
  const [errorGenCode, setErrorGenCode] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('sms');
  const [maskedContact, setMaskedContact] = useState('');
  const [methodInfo, setMethodInfo] = useState('');

  useEffect(() => {
    refreshCsrfToken();
    if (window.setupForgotPasswordButton) {
      window.setupForgotPasswordButton();
    }
  }, []);

  const refreshCsrfToken = () => {
    const { csrfCookieName, csrfTokenName } = config;
    if (!csrfCookieName || !csrfTokenName) {
      return '';
    }
    const parts = ('; ' + document.cookie).split('; ' + csrfCookieName + '=');
    if (parts.length === 2) {
      const token = parts.pop().split(';').shift();
      document.querySelectorAll(`input[type="hidden"][name="${csrfTokenName}"]`).forEach(input => {
        input.value = token;
      });
      setCsrfToken(token);
      return token;
    }
    return '';
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handle2FAChange = (e) => {
    setTwoFactorCode(e.target.value);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
  };

  // logowanie i wysłanie kodu 2fa
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    refreshCsrfToken();
    const { csrfTokenName, baseUrl } = config;

    fetch(`${baseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: new URLSearchParams({
        username,
        password,
        [csrfTokenName]: config.csrfTokenValue(),
        secretKeyMK: 'S^jban3211@!',
        method: selectedMethod,
      }).toString(),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        if (data.is2fa_required) {
          setShow2FA(true);
          setIsLoading(false);
          setUserId(data.user_id);
          setIsPasswordIncorrect(false);
          setMaskedContact(data.masked_contact);
          setMethodInfo(selectedMethod === 'sms' ? 'Wprowadź kod wysłany na numer:' : 'Wprowadź kod wysłany na adres:');
        } else if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      } else {
        setIsLoading(false);
        setIsPasswordIncorrect(true);
        setErrorMessage(data.error);
        setError(data.error);
      }
    })
    .catch(error => {
      setIsLoading(false);
      setIsPasswordIncorrect(true);
      setErrorMessage('An error occurred');
      setError('An error occurred');
    });
  };
  // sprwadz poprawność  kodu 2fa
  const handle2FASubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    refreshCsrfToken();
    const { csrfTokenName, baseUrl } = config;

    fetch(`${baseUrl}/login/verify2fa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: new URLSearchParams({
        username,
        password,
        [csrfTokenName]: config.csrfTokenValue(),
        secretKeyMK: 'S^jban3211@!',
        code: twoFactorCode,
        user_id: userId,
      }).toString(),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success && data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        setIsLoading(false);
        setIsPasswordIncorrect(true);
        setErrorMessage(data.error);
        setError(data.error);
      }
    })
    .catch(error => {
      setIsLoading(false);
      setIsPasswordIncorrect(true);
      setErrorMessage('Błąd podczas logowania');
      setError('An error occurred');
    });
  };

  const handleRegenerate2FACode = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setRegenerateCode(true);
    refreshCsrfToken();
    const { csrfTokenName, baseUrl } = config;

    fetch(`${baseUrl}/login/regenerate2fa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: new URLSearchParams({
        username,
        password,
        [csrfTokenName]: config.csrfTokenValue(),
        secretKeyMK: 'S^jban3211@!',
        user_id: userId,
        method: selectedMethod,
      }).toString(),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.success) {
          setRegenerateCode(false);
          setMaskedContact(data.masked_contact);
        } else {
          setErrorMessage(data.error);
          setError(data.error);
          setErrorGenCode(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage('An error occurred');
        setError('An error occurred');
      });
  };

  return (
    <>
      <div className="panel-heading pt-2 w-75" style={{ margin: '0 auto' }}>
        {isPasswordIncorrect ? (
          <h5 className="text-danger">
            <i className="fa fa-exclamation-circle mr-1"></i>
            {errorMessage}
          </h5>
        ) : (
          <h5>
            <i className="fa fa-circle mr-1"></i>
            {'Witamy w' + config.lang['app_name']}
          </h5>
        )}
      </div>
      <div className="panel-body pt-3 w-75" style={{ margin: '0 auto' }}>
        <form onSubmit={show2FA ? handle2FASubmit : handleSubmit} style={{ fontSize: '120%' }}>
          <input type="hidden" name={config.csrfTokenName} value={csrfToken} />
          <fieldset>
            {!show2FA && (
              <>
                <div className="form-group has-feedback">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    placeholder="Login"
                    required
                    autoComplete="on"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  <span className="glyphicon glyphicon-user form-control-feedback"></span>
                </div>
                <div className="form-group has-feedback">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Hasło"
                    required
                    autoComplete="on"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                </div>
                <div className="row pt-2 pb-3">
                  <div className="col-6">
                    <div className="dropdown">
                      <button className="btn btn-info dropdown-toggle btn-sm" type="button" data-toggle="dropdown" aria-expanded="false">
                        Wyślij kod: {selectedMethod === 'sms' ? 'SMS' : 'Email'}
                      </button>
                      <div className="dropdown-menu">
                        <div className="d-flex align-items-center">
                          {selectedMethod === 'email' && <i className="fa fa-check"></i>}
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => setSelectedMethod('email')}
                            style={{ marginLeft: selectedMethod === 'email' ? '0' : '14px' }}
                          >
                            Email
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          {selectedMethod === 'sms' && <i className="fa fa-check"></i>}
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => setSelectedMethod('sms')}
                            style={{ marginLeft: selectedMethod === 'sms' ? '0' : '14px' }}
                          >
                            Sms
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <a href="#" id="forgot-passwd-btn" className="small pull-right text-gray-darker">
                      <i className="fa fa-life-bouy"></i> Nie pamiętam hasła
                    </a>
                  </div>
                </div>
                <button className="btn btn-block btn-secondary" type="submit" disabled={isLoading}>
                  {isLoading ? <i className="fa fa-spinner fa-spin"></i> : 'Zaloguj się'}
                </button>
              </>
            )}
            {show2FA && (
              <>
                <div className="form-group has-feedback">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    placeholder="Login"
                    required
                    autoComplete="on"
                    value={username}
                    onChange={handleUsernameChange}
                    disabled={true}
                  />
                  <span className="glyphicon glyphicon-user form-control-feedback"></span>
                </div>
                <div className="form-group has-feedback">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Hasło"
                    required
                    autoComplete="on"
                    value={password}
                    onChange={handlePasswordChange}
                    disabled={true}
                  />
                  <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                </div>
                  <div className="alert alert-success small p-1">
                    {methodInfo} {maskedContact}
                  </div>
                <div className="form-group has-feedback">
                  <input
                    type="text"
                    name="2fa"
                    className="form-control"
                    placeholder="Wprowadź kod"
                    required
                    autoComplete="off"
                    value={twoFactorCode}
                    onChange={handle2FAChange}
                  />
                  <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                </div>
                <div className="row pt-2 pb-3">
                  <div className="col-6">
                    <div className="dropdown">
                      <button className="btn btn-info dropdown-toggle btn-sm" type="button" data-toggle="dropdown" aria-expanded="false">
                        Wyślij kod: {selectedMethod === 'sms' ? 'SMS' : 'Email'}
                      </button>
                      <div className="dropdown-menu">
                        <div className="d-flex align-items-center">
                          {selectedMethod === 'email' && <i className="fa fa-check"></i>}
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => setSelectedMethod('email')}
                            style={{ marginLeft: selectedMethod === 'email' ? '0' : '14px' }}
                          >
                            Email
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          {selectedMethod === 'sms' && <i className="fa fa-check"></i>}
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => setSelectedMethod('sms')}
                            style={{ marginLeft: selectedMethod === 'sms' ? '0' : '14px' }}
                          >
                            Sms
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                      {!regenerateCode && (
                        <a href="#" className="small pull-right text-primary" onClick={handleRegenerate2FACode}>
                          Wygeneruj ponownie kod
                        </a>
                      )}
                      {regenerateCode && (
                        <button className="btn btn-link small pull-right text-success" disabled>
                          Generowanie kodu...
                        </button>
                      )}
                      {errorGenCode && (
                        <div className="text-danger small pull-left no-margin-bottom">
                          <h5 className="text-danger">
                            <i className="fa fa-exclamation-circle icon-spacing mr-1"></i>
                            Błąd generacji kodu
                          </h5>
                        </div>
                      )}
                    
                  </div>
                </div>
                <button
                  className="btn btn-block btn-secondary"
                  type="submit"
                  disabled={isLoading}
                  style={show2FA ? { cursor: 'pointer', fontWeight: 'bold', color: 'green' } : {}}
                >
                  {isLoading ? <i className="fa fa-spinner fa-spin"></i> : 'Potwierdź kod'}
                </button>
              </>
            )}
          </fieldset>
        </form>
      </div>
    </>
  );
};

Login.propTypes = {
  config: PropTypes.shape({
    csrfCookieName: PropTypes.string.isRequired,
    csrfTokenName: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
    baseUrl: PropTypes.string.isRequired,
    csrfTokenValue: PropTypes.func,
  }).isRequired,
};

export default Login;