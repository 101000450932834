/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './Login';
import AnotherComponent from './AnotherComponent';
import App from './App';


// Inicjalizacja obiektu handleReact
const handleReact = {
  renderComponent(Component, elementId, props = {}) {
    const element = document.getElementById(elementId);
    if (element) {
      const root = ReactDOM.createRoot(element);
      root.render(
        <React.StrictMode>
          <Component {...props} />
        </React.StrictMode>
      );
      // Przechowuj referencję do root w obiekcie handleReact
      this[elementId] = root;
    }
  },
  unmountComponent(elementId) {
    const root = this[elementId];
    if (root) {
      root.unmount();
      delete this[elementId];
    }
  }
};

// Eksportuj obiekt handleReact
export default handleReact;

const lang2 = (key) => {
  const translations = {
    login: 'Login',
    password: 'Password',
    remember_me: 'Remember Me',
    forgot_password: 'Forgot Password',
    sign_in: 'Sign In',
  };
  return translations[key];
};


// Pobierz wartości CSRF z globalnych zmiennych
const baseUrl = (window.config && window.config.baseUrl)  || "http://localhost:8092/first_fm/login/";
const csrfTokenName = (window.config && window.config.csrfTokenName)  || "csrfToken" ;
const csrfCookieName = (window.config && window.config.csrfCookieName)  || "csrfCookie";
const lang = (window.config && window.config.lang) || {
  login: 'Login',
  password: 'Password',
  remember_me: 'Remember Me',
  forgot_password: 'Forgot Password',
  sign_in: 'Sign In',
};
const defaultCsrfToken = () => '';
/* eslint-disable no-undef */
const csrfTokenValue = () => (typeof refreshCsrfToken === 'function' ? refreshCsrfToken() : defaultCsrfToken());
/* eslint-enable no-undef */

const config = { csrfTokenName, csrfCookieName, lang, baseUrl, csrfTokenValue };
// Renderowanie komponentów na podstawie obecności elementów DOM
handleReact.renderComponent(Login, 'react-login', {config, lang, baseUrl });
handleReact.renderComponent(AnotherComponent, 'react-another-component', { csrfTokenName, csrfTokenValue, lang, baseUrl });


